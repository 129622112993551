export default function () {
	const units = document.querySelectorAll('.js-tab-unit');

	[].forEach.call(units, function(unit) {
		const btns = unit.querySelectorAll('[role="tablist"] button');
		const panels = unit.querySelectorAll('[role="tabpanel"]');

		[].forEach.call(btns, function(btn) {
			btn.onclick = function(e) {
				const el = e.currentTarget;
				const targetId = el.getAttribute('aria-controls');
				[].forEach.call(btns, function(btn) {
					btn.setAttribute('aria-selected', 'false');
				});
				[].forEach.call(panels, function(panel) {
					panel.style.display = 'none';
				});
				el.setAttribute('aria-selected', 'true');
				$('#'+targetId).fadeIn(400);
			};
		});
	});
}
