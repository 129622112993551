import {BREAK_POINTS} from '../settings';
import {myDevice} from './device';

export default function () {

  // スマホタブレットでなければロールオーバーで使用する（二回タップ対策用）クラスを追加

  if (window.innerWidth > BREAK_POINTS.ltSP) {
    const hoverItems = document.querySelectorAll('a');
    const hoverBtns = document.querySelectorAll('button');

    [].forEach.call(hoverItems, function(el) {
      el.classList.add('is-hover');
    });
    [].forEach.call(hoverBtns, function(el) {
      el.classList.add('is-hover');
    });
  }

}
