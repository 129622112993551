let isTouch = ('createTouch' in document);
let isAndroid = false;
let isTouchDevice = false;
if(window.navigator.userAgent.toLowerCase().indexOf("android") != -1) {
	isAndroid = true;
}
if(isAndroid) {
	isTouchDevice = true;
}
if(isTouch) {
	isTouchDevice = true;
}
const myDevice = {
	isTouch : isTouch,
	isAndroid : isAndroid,
	isTouchDevice : isTouchDevice
};

export {myDevice};
