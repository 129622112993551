import {BREAK_POINTS} from './settings';
import includeHTML from './module/jquery.include';
import {slideToggle} from './module/slideToggle';
import pcAddIshover from './module/pcAddIshover';
import scroll2AddClass from './module/scroll2AddClass';
import gnavToggle from './module/gnavToggle';
import smoothScroll from './module/smoothScroll';
import tab from './module/tab';
import gnavmenu from './module/gnavmenu';

(function () {
	includeHTML();

	$(window).on('included', function() {
		console.log("included!");
    pcAddIshover();
    scroll2AddClass();
    smoothScroll();
    tab();
    gnavmenu();
		gnavToggle();

    const unitSPEl = document.querySelectorAll('.js-sp-slide-unit');
    [].forEach.call(unitSPEl, function (el) {
      const btn = el.querySelector('.js-sp-slide-switch')
      const elTarget = el.querySelector('.js-sp-slide-target');
      btn.addEventListener('click', function(e) {
				if (window.innerWidth <= BREAK_POINTS.ltSP) {
					e.preventDefault();
					slideToggle(e.currentTarget, elTarget);
				}
      });
    });
  });

})();
