import {BREAK_POINTS} from '../settings';
import {slideToggle} from './slideToggle';

export default function () {
  const overlay = $('#js-overlay');
	const triggers = $('.js-haschild');
  haschildEls = $('.js-gnav-wrapper .js-haschild');
  let t = null;
	let target = null;
	let zCounter = 1;

  triggers.on('mouseover', function() {
    if(window.innerWidth > BREAK_POINTS.ltSP) {
      isOn = true;
      target = $(this).find('.js-child');
      const els =  $('.js-child').not(target);
      els.hide();
			target.css('z-index', zCounter);
			zCounter += 1;
      target.fadeIn(function() {
				const els =  $('.js-child').not(target);
				els.fadeOut(30);
			});
      overlay.fadeIn();
    }
  });

  triggers.on('mouseout', function() {
    if(window.innerWidth > BREAK_POINTS.ltSP) {
      isOn = false;
      t = $(this).find('.js-child');
      setTimeout(function() {
        if(!isOn) {
          t.fadeOut();
          overlay.fadeOut();
        }
      }, 100)
    }
  });

  overlay.on('mouseover', function() {
    if(window.innerWidth > BREAK_POINTS.ltSP) {
      isOn = false;
      $('.js-child').fadeOut();
      overlay.fadeOut();
    }
  });

  [].forEach.call(haschildEls, function(el) {
    const btn = el.querySelector('.l-nav__link')
    const elTarget = el.querySelector('.js-child');
    btn.addEventListener('click', function(e) {
      e.preventDefault();
      if(window.innerWidth <= BREAK_POINTS.ltSP) {
        slideToggle(e.currentTarget, elTarget);
      }
    });
  });
}
