export default function () {
	let counter = 0;
	const maxCount = 4;
	$('#js-header').load("/common/include/header.html", function() {
		counter += 1;
		if(counter >= maxCount) {
			$(window).trigger('included');
		}
	});
	$('#js-contact').load("/common/include/contact.html", function() {
		counter += 1;
		if(counter >= maxCount) {
			$(window).trigger('included');
		}
	});
	$('#js-pagetop').load("/common/include/pagetop.html", function() {
		counter += 1;
		if(counter >= maxCount) {
			$(window).trigger('included');
		}
	});
	$('#js-footer').load("/common/include/footer.html", function() {
		counter += 1;
		if(counter >= maxCount) {
			$(window).trigger('included');
		}
	});
}
